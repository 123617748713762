import { CompanyBaseModel } from "./company-base-model";
import { UserModel } from "./user-model";

export class CompanyModel extends CompanyBaseModel {
  public accountableText: string = "Accountable";
  public isMultiFactorAuthenticationEnabled: boolean = false;
  public isNotificationsEnabled: boolean = true;
  public shouldHidePrismUsers: boolean = false;
  public swimlaneColor: string = "#F5F5F5";
  public users: Array<UserModel> = [];
  public userLimit: number;

  constructor(data?: any) {
    super();

    if (data) {
      Object.assign(this, data);
    }
  }
 }
