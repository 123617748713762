import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ProcessRegisterModel } from '../../models/process-register-model';
import { MatSort } from '@angular/material/sort';
import { ProcessService } from '../../services/process.service';
import { MatTableUtility } from '../../utility/mat-table-utility';
import { FileDocumentUtility } from '../../utility/file-document-utility';
import { DatePipe } from '@angular/common';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-process-register',
  templateUrl: './process-register.component.html',
  styleUrls: ['./process-register.component.scss']
})

export class ProcessRegisterComponent implements OnInit {
  public isLoading: boolean = true;
  public isBusy: boolean = false;
  public processRegisterTableData = new MatTableDataSource<ProcessRegisterModel>([]);
  public processRegisterColumns: string[] = ['processGroup', 'reference', 'processTitle', 'revision', 'revisionDate', 'draft', 'owner', 'approver'];

  private pipe: DatePipe;
  private processSort: MatSort

  @ViewChild('processRegisterTable', { read: MatSort, static: false }) set processRegisterSortValue(value: MatSort) {
    if (value) {
      this.processSort = value;
      this.processRegisterTableData.sort = this.processSort
    }
  };

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.processRegisterTableData.filter = filterValue.trim().toLowerCase();
  }

  constructor(public authService: AuthService,
    private processService: ProcessService) {
    this.pipe = new DatePipe('en');
    const defaultPredicate = this.processRegisterTableData.filterPredicate;
    this.processRegisterTableData.filterPredicate = (data, filter) => {
      const formattedDate = this.pipe.transform(data.revisionDate, 'dd/MM/yyyy');
      return (formattedDate != null && formattedDate.indexOf(filter) >= 0) || defaultPredicate(data, filter);
    }
  }

  public ngOnInit(): void {
    this.processRegisterTableData.sortingDataAccessor = MatTableUtility.caseInsensitiveSortingDataAccessor;

    this.processService.getProcessRegister().subscribe({
      next: (response: Array<ProcessRegisterModel>) => {
        this.processRegisterTableData.data = response;
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false
      }
    });
  }

  public export(): void {
    this.isBusy = true;
    this.processService.exportRegister().subscribe({
      next: (response) => {
        FileDocumentUtility.openFileDocument(response);
        this.isBusy = false;
      }
    });
  }
}
