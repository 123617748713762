<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<section class="dialog-container" *ngIf="!isLoading">
  <div>
    <h2 mat-dialog-title>{{ dialogTitle }}</h2>
  </div>

  <mat-dialog-content>
    <form id="resourceForm" #resourceForm="ngForm" (ngSubmit)="save(resourceForm)">
      <mat-form-field color="accent" *ngIf="canEdit()">
        <mat-label>Title</mat-label>
        <input matInput
               placeholder="Enter title..."
               name="title"
               [(ngModel)]="model.title"
               #title="ngModel"
               [disabled]="isBusy"
               required>
        <mat-error *ngIf="title.invalid">
          Title is required
        </mat-error>
      </mat-form-field>

      <div class="readonly-section" *ngIf="!canEdit()">
        <label class="readonly-label">
          Title
        </label>
        <div class="readonly-content">
          {{ model.title }}
        </div>
      </div>

      <mat-form-field color="accent" *ngIf="canEdit()">
        <mat-label>Unit of Measure</mat-label>
        <input matInput
               placeholder="Enter unit of measurement..."
               name="unitOfMeasure"
               [disabled]="isBusy"
               [(ngModel)]="model.unitOfMeasure">
      </mat-form-field>

      <div class="readonly-section" *ngIf="!canEdit()">
        <label class="readonly-label">
          Unit Of Measure
        </label>
        <div class="readonly-content">
          {{ model.unitOfMeasure }}
        </div>
      </div>

      <mat-form-field color="accent" *ngIf="canEdit()">
        <mat-label>Quantity</mat-label>
        <input matInput
               placeholder="Enter quantity..."
               name="quantity"
               [disabled]="isBusy"
               [(ngModel)]="model.quantity">
      </mat-form-field>

      <div class="readonly-section" *ngIf="!canEdit()">
        <label class="readonly-label">
          Quantity
        </label>
        <div class="readonly-content">
          {{ model.quantity }}
        </div>
      </div>

      <mat-form-field color="accent" *ngIf="canEdit()">
        <mat-label>Description</mat-label>
        <textarea matInput
                  placeholder="Enter description..."
                  name="description"
                  [disabled]="isBusy"
                  [(ngModel)]="model.description"></textarea>
      </mat-form-field>

      <div class="readonly-section" *ngIf="!canEdit()">
        <label class="readonly-label">
          Description
        </label>
        <div class="readonly-content">
          {{ model.description }}
        </div>
      </div>

      <app-autocomplete *ngIf="canEdit()"
                        [label]="'Owner'"
                        [placeholder]="'Select a Role'"
                        [(value)]="model.ownerRoleId"
                        [options]="roles"
                        [isDisabled]="isBusy">
      </app-autocomplete>

      <div class="readonly-section" *ngIf="!canEdit()">
        <label class="readonly-label">
          Owner
        </label>
        <div class="readonly-content">
          {{ getRoleTitle(model.ownerRoleId) }}
        </div>
      </div>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-raised-button class="button" (click)="close()" [disabled]="isBusy">Close</button>
    <button type="submit" *ngIf="canEdit()" mat-raised-button color="accent" class="button accent-button" form="resourceForm" [disabled]="isBusy">Save</button>
  </mat-dialog-actions>
</section>
