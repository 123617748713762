import * as moment from "moment";
import { AutocompleteOptionModel } from "./autocomplete-option-model";
import { UserRoleModel } from '../models/user-role-model';

export class RoleModel extends AutocompleteOptionModel {
  public roleId: number;
  public roleGroupId: number;
  public title: string;
  public description: string | null;
  public emailAddress: string | null;
  public reportsToRoleId: number | null;
  public createdUtc: moment.Moment;
  public modifiedUtc: moment.Moment;
  public userId: number | null

  public userRoles: Array<UserRoleModel> = [];

  constructor(data?: any) {
    super();

    if (data) {
      this.id = data.roleId;
      this.displayValue = data.title;

      if (data.userRoles) {
        data.userRoles = data.userRoles.map((i: any) => new UserRoleModel(i));
      }

      Object.assign(this, data);
    }
  }
}
