import { FileDocumentMetadataModel } from "./file-document-metadata-model";

export class ConfigurationModel {
  public configurationId: number;
  public attachableFileTypes: string;
  public attachableFileMaxSizeMB: number;
  public userGuideFileDocumentId: number | null;

  public userGuideFileDocument: FileDocumentMetadataModel | null;

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
