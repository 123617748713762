<section class="dialog-container">
  <div>
    <h2 mat-dialog-title>{{ dialogTitle }}</h2>
  </div>

  <mat-dialog-content>
    <form id="customGroupForm" #customGroupForm="ngForm" (ngSubmit)="save(customGroupForm)">
      <mat-form-field color="accent" *ngIf="canEdit()">
        <mat-label>Title</mat-label>
        <input matInput
               placeholder="Enter title..."
               name="title"
               [(ngModel)]="model.title"
               #title="ngModel"
               [disabled]="isBusy"
               required>
        <mat-error *ngIf="title.invalid">
          Title is required
        </mat-error>
      </mat-form-field>

      <div class="readonly-section" *ngIf="!canEdit()">
        <label class="readonly-label">
          Title
        </label>
        <div class="readonly-content">
          {{model.title}}
        </div>
      </div>

      <mat-form-field color="accent" *ngIf="canEdit()">
        <mat-label>Description</mat-label>
        <textarea matInput
                  placeholder="Enter description..."
                  name="description"
                  [disabled]="isBusy"
                  [(ngModel)]="model.description"></textarea>
      </mat-form-field>

      <div class="readonly-section" *ngIf="!canEdit()">
        <label class="readonly-label">
          Description
        </label>
        <div class="readonly-content">
          {{model.description}}
        </div>
      </div>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-raised-button class="button" (click)="close()" [disabled]="isBusy">Close</button>
    <button type="submit" *ngIf="canEdit()" mat-raised-button color="accent" class="button accent-button" form="customGroupForm" [disabled]="isBusy">Save</button>
  </mat-dialog-actions>
</section>
