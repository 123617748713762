import * as moment from "moment";

export class ProcessRevisionModel {
  public processRevisionId: number | null;
  public processId: number | null;
  public revisedDate: moment.Moment | null;
  public number: string | null;
  public reason: string | null;
  public createdUtc: moment.Moment;
  public modifiedUtc: moment.Moment;

  constructor(data?: any) {
    if (data) {

      if (data.revisedDate) {
        data.revisedDate = moment(data.revisedDate);
      }

      Object.assign(this, data);
    }
  }
}
