import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DocumentRegisterModel } from '../../models/document-register-model';
import { MatSort } from '@angular/material/sort';
import { DocumentService } from '../../services/document.service';
import { MatTableUtility } from '../../utility/mat-table-utility';
import { FileDocumentUtility } from '../../utility/file-document-utility';
import { FileDocumentService } from '../../services/file-document-service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-document-register',
  templateUrl: './document-register.component.html',
  styleUrls: ['./document-register.component.scss']
})

export class DocumentRegisterComponent implements OnInit {
  public isLoading: boolean = true;
  public isBusy: boolean = false;
  public documentRegisterTableData = new MatTableDataSource<DocumentRegisterModel>([]);
  public documentRegisterColumns: string[] =
    ['documentGroup', 'document', 'title', 'version', 'retention', 'reviewDueDate', 'reviewFrequency', 'owner', 'approver', 'description'];

  private pipe: DatePipe;
  private documentSort: MatSort

  @ViewChild('documentRegisterTable', { read: MatSort, static: false }) set documentRegisterSortValue(value: MatSort) {
    if (value) {
      this.documentSort = value;
      this.documentRegisterTableData.sort = this.documentSort;
    }
  };

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.documentRegisterTableData.filter = filterValue.trim().toLowerCase();
  }

  constructor(private documentService: DocumentService,
    private fileDocumentService: FileDocumentService) {
    this.pipe = new DatePipe('en');
    const defaultPredicate = this.documentRegisterTableData.filterPredicate;
    this.documentRegisterTableData.filterPredicate = (data, filter) => {
      const formattedDate = this.pipe.transform(data.reviewDueDate, 'dd/MM/yyyy');
      return (formattedDate != null && formattedDate.indexOf(filter) >= 0) || defaultPredicate(data, filter);
    }
  }

  public ngOnInit(): void {
    this.documentRegisterTableData.sortingDataAccessor = MatTableUtility.caseInsensitiveSortingDataAccessor;

    this.documentService.getDocumentRegister().subscribe({
      next: (response: Array<DocumentRegisterModel>) => {
        this.documentRegisterTableData.data = response;
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      }
    });
  }

  public downloadDocument(document: DocumentRegisterModel): void {
    if (document.fileDocument) {
      this.fileDocumentService.getFileDocument(document.fileDocument).subscribe({
        next: (response: any) => {
          FileDocumentUtility.openFileDocument(response);
        }
      });
    }
  }

  public export(): void {
    this.isBusy = true;
    this.documentService.exportRegister().subscribe({
      next: (response) => {
        FileDocumentUtility.openFileDocument(response);
        this.isBusy = false;
      }
    });
  }
}
