import { Component, OnInit, ViewChild } from "@angular/core";
import { ResourceGroupModel } from '../../models/resource-group-model';
import { ResourceModel } from '../../models/resource-model';
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from '@angular/material/sort';
import { ResourceService } from '../../services/resource.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AuthService } from "../../services/auth.service";
import { MatTableUtility } from '../../utility/mat-table-utility';
import { ResourceGroupDialogComponent } from './resource-group-dialog/resource-group-dialog.component';
import { ResourceDialogComponent } from './resource-dialog/resource-dialog.component';

@Component({
  selector: 'app-resource-list',
  templateUrl: './resource-list.component.html',
  styleUrls: ['./resource-list.component.scss']
})
export class ResourceListComponent implements OnInit {
  public resourceGroups: Array<ResourceGroupModel>;
  public isLoading: boolean;
  public selectedResourceGroup: ResourceGroupModel | null = null;

  public resourceTableData = new MatTableDataSource<ResourceModel>([]);
  public resourceColumns: string[] = ['title', 'controls'];

  public resourceGroupTableData = new MatTableDataSource<ResourceGroupModel>([]);
  public resourceGroupColumns: string[] = ['title', 'controls'];

  public resourceSort: MatSort
  public resourceGroupSort: MatSort;

  @ViewChild('resourceGroupTable', { read: MatSort, static: false }) set resourceGroupSortValue(value: MatSort) {
    if (value) {
      this.resourceGroupSort = value;
      this.resourceGroupTableData.sort = this.resourceGroupSort;
    }
  };

  @ViewChild('resourceTable', { read: MatSort, static: false }) set resourceSortValue(value: MatSort) {
    if (value) {
      this.resourceSort = value;
      this.resourceTableData.sort = this.resourceSort;
    }
  };

  constructor(private resourceService: ResourceService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    public authService: AuthService) {
  }

  public ngOnInit(): void {
    this.isLoading = true;

    this.resourceTableData.sortingDataAccessor = MatTableUtility.caseInsensitiveSortingDataAccessor;
    this.resourceGroupTableData.sortingDataAccessor = MatTableUtility.caseInsensitiveSortingDataAccessor;

    this.resourceService.getResourceGroups().subscribe({
      next: (response: Array<ResourceGroupModel>) => {
        this.resourceGroups = response;
        this.refreshResourceGroupTableData();
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      }
    });
  }

  public canEdit(): boolean {
    return this.authService.canCurrentUserEdit;
  }

  public selectResourceGroup(resourceGroup: ResourceGroupModel) {
    if (this.selectedResourceGroup != resourceGroup) {
      this.selectedResourceGroup = resourceGroup;
      this.refreshResourceTableData();
    }
  }

  public createResourceGroup() {
    if (!this.canEdit()) {
      return;
    }

    const resourceGroup = new ResourceGroupModel();

    const dialogConfig: MatDialogConfig = {
      data: {
        ...resourceGroup,
      }
    };

    const dialogRef = this.dialog.open(ResourceGroupDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: ResourceGroupModel) => {
        if (result) {
          this.resourceGroups.push(result);
          this.selectedResourceGroup = result;
          this.refreshResourceTableData();
          this.refreshResourceGroupTableData();
        }
      }
    });
  }

  public editResourceGroup(resourceGroup: ResourceGroupModel) {
    const dialogConfig: MatDialogConfig = {
      data: {
        ...resourceGroup,
      },
      autoFocus: this.canEdit(),
    };

    const dialogRef = this.dialog.open(ResourceGroupDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: ResourceGroupModel) => {
        if (result) {
          const index = this.resourceGroups.findIndex(i => i.resourceGroupId == result.resourceGroupId);
          this.resourceGroups[index] = result;
          this.refreshResourceGroupTableData();
        }
      }
    });
  }

  public deleteResourceGroup(resourceGroup: ResourceGroupModel) {
    if (!this.canEdit()) {
      return;
    }

    if (!confirm(`Are you sure you want to delete Resource Group "${resourceGroup.title}"?`)) {
      return;
    }

    this.resourceService.deleteResourceGroup(resourceGroup.resourceGroupId).subscribe({
      next: () => {
        this.resourceGroups.splice(this.resourceGroups.indexOf(resourceGroup), 1);
        this.refreshResourceGroupTableData();
        this.toastr.success(`Resource Group "${resourceGroup.title}" deleted`);
      }
    });
  }

  public createResource() {
    if (!this.canEdit()) {
      return;
    }

    if (!this.selectedResourceGroup) {
      return;
    }

    const resource = new ResourceModel();
    resource.resourceGroupId = this.selectedResourceGroup.resourceGroupId;

    const dialogConfig: MatDialogConfig = {
      data: {
        ...resource
      }
    };

    const dialogRef = this.dialog.open(ResourceDialogComponent, dialogConfig)

    dialogRef.afterClosed().subscribe({
      next: (result: ResourceModel) => {
        if (result) {
          this.selectedResourceGroup!.resources.push(result);
          this.refreshResourceTableData();
        }
      }
    });
  }

  public editResource(resource: ResourceModel) {
    const dialogConfig: MatDialogConfig = {
      data: {
        ...resource,
      }
    };

    const dialogRef = this.dialog.open(ResourceDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: ResourceModel) => {
        if (result) {
          const resourceGroup = this.resourceGroups.find(i => i.resourceGroupId == result.resourceGroupId);
          if (resourceGroup) {
            const index = resourceGroup.resources.findIndex(i => i.resourceId == result.resourceId);
            resourceGroup.resources[index] = result;
            this.refreshResourceTableData();
          }
        }
      }
    });
  }

  public deleteResource(resource: ResourceModel) {
    if (!this.canEdit()) {
      return;
    }

    if (!confirm(`Are you sure you want to delete Resource "${resource.title}"?`)) {
      return;
    }

    this.resourceService.deleteResource(resource.resourceId).subscribe({
      next: () => {
        const resourceGroup = this.resourceGroups.find(i => i.resourceGroupId == resource.resourceGroupId);
        if (resourceGroup) {
          resourceGroup.resources.splice(resourceGroup.resources.indexOf(resource), 1);
          this.refreshResourceTableData();
          this.toastr.success(`Resource "${resource.title}" deleted`);
        }
      }
    });
  }

  private refreshResourceGroupTableData(): void {
    this.resourceGroupTableData.data = this.resourceGroups;
  }

  private refreshResourceTableData(): void {
    if (this.selectedResourceGroup) {
      this.resourceTableData.data = this.selectedResourceGroup.resources;
    } else {
      this.resourceTableData.data = [];
    }
  }
}
