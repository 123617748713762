import * as moment from "moment";
import { ProcessModel } from "./process-model";
import { CategoryModel } from "./category-model";
import { AutocompleteOptionModel } from "./autocomplete-option-model";

export class ProcessGroupModel extends AutocompleteOptionModel {
  public processGroupId: number;
  public companyId: number;
  public title: string;
  public description: string;
  public createdUtc: moment.Moment;
  public modifiedUtc: moment.Moment;

  public processes: Array<ProcessModel> = [];
  public categories: Array<CategoryModel> = [];

  constructor(data?: any) {
    super();
    if (data) {
      this.id = data.processGroupId;
      this.displayValue = data.title;

      if (data.processes) {
        data.processes = data.processes.map((i: any) => new ProcessModel(i));
      }

      if (data.categories) {
        data.categories = data.categories.map((i: any) => new CategoryModel(i));
      }

      Object.assign(this, data);
    }
  }
}
