import * as moment from "moment";
import { ResourceModel } from "./resource-model";

export class ResourceGroupModel {
  public resourceGroupId: number;
  public companyId: number;
  public title: string;
  public description: string | null;
  public createdUtc: moment.Moment;
  public modifiedUtc: moment.Moment;

  public resources: Array<ResourceModel> = [];
  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
