import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProcessRevisionModel } from '../../../../models/process-revision-model';
import { AuthService } from '../../../../services/auth.service';
import { ProcessService } from '../../../../services/process.service';
import { ToastrService } from 'ngx-toastr';
import { ValidationService } from '../../../../services/validation-service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-process-revision-dialog',
  templateUrl: './process-revision-dialog.component.html',
  styleUrls: ['./process-revision-dialog.component.scss']
})
export class ProcessRevisionDialogComponent {
  public dialogTitle: string | null = "New Process Revision";
  public isBusy: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public processRevision: ProcessRevisionModel,
    private dialogRef: MatDialogRef<ProcessRevisionDialogComponent>,
    private authService: AuthService,
    private processService: ProcessService,
    private toastr: ToastrService,
    private validationService: ValidationService) {

    if (processRevision.processRevisionId) {
      this.dialogTitle = this.processRevision.number;
    }
  }

  public canEdit(): boolean {
    return this.authService.canCurrentUserEdit;
  }

  public save(form: NgForm) {
    if (!this.canEdit()) {
      return;
    }

    if (this.validationService.isFormValid(form)) {
      this.isBusy = true;

      this.processService.saveProcessRevision(this.processRevision).subscribe({
        next: (response: ProcessRevisionModel) => {
          this.processRevision = response;
          this.isBusy = false;
          this.toastr.success("Process Revision Saved");
          this.dialogRef.close(this.processRevision);
        }, error: () => {
          this.isBusy = false;
        }
      });
    }
  }

  public close() {
    this.dialogRef.close();
  }
}
