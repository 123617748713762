import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { RoleGroupModel } from '../../../models/role-group-model';
import { RoleService } from '../../../services/role.service';
import { AuthService } from '../../../services/auth.service';
import { NgForm } from '@angular/forms';
import { ValidationService } from '../../../services/validation-service';

@Component({
  selector: 'app-role-group-dialog',
  templateUrl: './role-group-dialog.component.html',
  styleUrls: ['./role-group-dialog.component.scss']
})
export class RoleGroupDialogComponent {
  public model: RoleGroupModel;
  public dialogTitle: string = "New Role Group";
  public isBusy: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) private roleGroup: RoleGroupModel,
    private dialogRef: MatDialogRef<RoleGroupDialogComponent>,
    private roleService: RoleService,
    private toastr: ToastrService,
    private authService: AuthService,
    private validationService: ValidationService) {
    this.model = roleGroup;

    if (this.model.roleGroupId && this.model.roleGroupId != 0) {
      this.dialogTitle = this.model.title;
    }
  }

  public canEdit(): boolean {
    return this.authService.canCurrentUserEdit;
  }

  public save(form: NgForm) {
    if (!this.canEdit()) {
      return;
    }

    if (this.validationService.isFormValid(form)) {
      this.isBusy = true;

      this.roleService.saveRoleGroup(this.model).subscribe({
        next: (response: RoleGroupModel) => {
          this.model = response;
          this.toastr.success("Role Group saved");
          this.dialogRef.close(this.model);
        },
        error: () => {
          this.isBusy = false;
        }
      });
    }
  }

  public close() {
    this.dialogRef.close();
  }
}

