import * as moment from "moment";
import { DocumentModel } from "./document-model";
import { CategoryModel } from "./category-model";

export class DocumentGroupModel {
  public documentGroupId: number;
  public companyId: number;
  public title: string;
  public description: string;
  public createdUtc: moment.Moment;
  public modifiedUtc: moment.Moment;

  public documents: Array<DocumentModel> = [];
  public categories: Array<CategoryModel> = [];

  constructor(data?: any) {
    if (data) {
      if (data.documents) {
        data.documents = data.documents.map((i: any) => new DocumentModel(i));
      }

      if (data.categories) {
        data.categories = data.categories.map((i: any) => new CategoryModel(i));
      }

      Object.assign(this, data);
    }
  }
}
