import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Observable, map } from "rxjs";
import { ConfigurationModel } from "../models/configuration-model";
import { FileDocumentMetadataModel } from "../models/file-document-metadata-model";

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  constructor(private apiService: ApiService) {
  }

  public getConfiguration(): Observable<ConfigurationModel> {
    return this.apiService.get("configuration/get")
      .pipe(map(i => new ConfigurationModel(i)));
  }

  public saveConfiguration(model: ConfigurationModel): Observable<ConfigurationModel> {
    return this.apiService.post("configuration/save", model)
      .pipe(map(i => new ConfigurationModel(i)));
  }

  public uploadUserGuide(data: FormData): Observable<FileDocumentMetadataModel | null> {
    return this.apiService.post(`configuration/upload-user-guide`, data)
      .pipe(map(i => new FileDocumentMetadataModel(i)));
  }

  public getHasUserManual(): Observable<boolean> {
    return this.apiService.get("configuration/has-user-manual");
  }

  public getUserManual(): Observable<any> {
    return this.apiService.postExportRaw("configuration/get-user-manual", {});
  }
}
