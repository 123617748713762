import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { StandardRegisterModel } from '../../models/standard-register-model';
import { MatSort } from "@angular/material/sort";
import { StandardService } from "../../services/standard.service";
import { MatTableUtility } from "../../utility/mat-table-utility";
import { FileDocumentUtility } from "../../utility/file-document-utility";

@Component({
  selector: 'app-standard-register',
  templateUrl: './standard-register.component.html',
  styleUrls: ['./standard-register.component.scss']
})

export class StandardRegisterComponent implements OnInit {
  public isLoading: boolean = true;
  public isBusy: boolean = false;
  public standardRegisterTableData = new MatTableDataSource<StandardRegisterModel>([]);
  public standardRegisterColumns: string[] = ['standard', 'standardSection', 'owner'];

  private standardSort: MatSort;

  @ViewChild('standardRegisterTable', { read: MatSort, static: false }) set standardRegisterSortValue(value: MatSort) {
    if (value) {
      this.standardSort = value;
      this.standardRegisterTableData.sort = this.standardSort;
    }
  };

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.standardRegisterTableData.filter = filterValue.trim().toLowerCase();
  }

  constructor(private standardService: StandardService) {
  }

  public ngOnInit(): void {
    this.standardRegisterTableData.sortingDataAccessor = MatTableUtility.caseInsensitiveSortingDataAccessor;

    this.standardService.getStandardRegister().subscribe({
      next: (response: Array<StandardRegisterModel>) => {
        this.standardRegisterTableData.data = response;
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      }
    });
  }

  public export(): void {
    this.isBusy = true;
    this.standardService.exportRegister().subscribe({
      next: (response) => {
        FileDocumentUtility.openFileDocument(response);
        this.isBusy = false;
      }
    });
  }
}
