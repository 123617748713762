<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<section class="dialog-container" *ngIf="!isLoading">
  <div>
    <h2 mat-dialog-title>Select User Role</h2>
  </div>

    <mat-dialog-content>
      <mat-form-field color="accent">
        <mat-label>Select User</mat-label>
        <mat-select [(ngModel)]="selectionId" name="selectionId">
          <mat-option *ngFor="let user of userOptions" [value]="user.userId">
            {{ user.displayName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <button mat-raised-button class="button" type="button" (click)="close()">Cancel</button>
    </mat-dialog-actions>
</section>
