import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Observable, map } from "rxjs";
import { ResourceGroupModel } from '../models/resource-group-model';
import { ResourceModel } from '../models/resource-model';
import { ResourceRegisterModel } from "../models/resource-register-model";

@Injectable({
  providedIn: 'root'
})
export class ResourceService {
  constructor(private apiService: ApiService) {
  }

  public getResourceGroups(): Observable<Array<ResourceGroupModel>> {
    return this.apiService.get("resource/group/get-all")
      .pipe(map((response: Array<ResourceGroupModel>) => response.map(i => new ResourceGroupModel(i))));
  }

  public getResources(): Observable<Array<ResourceModel>> {
    return this.apiService.get("resource/get-all")
      .pipe(map((response: Array<ResourceModel>) => response.map(i => new ResourceModel(i))));
  }

  public saveResource(model: ResourceModel): Observable<ResourceModel> {
    return this.apiService.post("resource", model)
      .pipe(map(i => new ResourceModel(i)));
  }

  public saveResourceGroup(model: ResourceGroupModel): Observable<ResourceGroupModel> {
    return this.apiService.post("resource/group", model)
      .pipe(map(i => new ResourceGroupModel(i)));
  }

  public deleteResourceGroup(id: number): Observable<void> {
    return this.apiService.post(`resource/group/delete/${id}`, {});
  }

  public deleteResource(id: number): Observable<void> {
    return this.apiService.post(`resource/delete/${id}`, {});
  }

  public getResourcesForProcess(processId: number): Observable<Array<ResourceModel>> {
    return this.apiService.get(`resource/process/${processId}`)
      .pipe(map((response: Array<ResourceModel>) => response.map(i => new ResourceModel(i))));
  }

  public saveProcessResource(processId: number, resourceId: number): Observable<ResourceModel> {
    return this.apiService.post(`resource/process/save/${processId}/${resourceId}`, {})
      .pipe(map(i => new ResourceModel(i)));
  }

  public deleteProcessResource(processId: number, resourceId: number): Observable<void> {
    return this.apiService.post(`resource/process/delete/${processId}/${resourceId}`, {})
  }

  public getResourceRegister(): Observable<Array<ResourceRegisterModel>> {
    return this.apiService.get("resource/register")
      .pipe(map((response: Array<ResourceRegisterModel>) => response.map(i => new ResourceRegisterModel(i))));
  }

  public exportRegister(): Observable<any> {
    return this.apiService.postExportRaw(`resource/register-export`, {});
  }

}
