import { Component, Inject, OnInit } from '@angular/core';
import { RoleModel } from '../../../models/role-model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomService } from '../../../services/custom.service';
import { RoleService } from '../../../services/role.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../services/auth.service';
import { RoleTitleUtility } from '../../../utility/role-title-utility';
import { CustomModel } from '../../../models/custom-model';
import { ValidationService } from '../../../services/validation-service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-custom-dialog',
  templateUrl: './custom-dialog.component.html',
  styleUrls: ['./custom-dialog.component.scss']
})
export class CustomDialogComponent implements OnInit {
  public model: CustomModel;
  public dialogTitle: string = "New Custom";
  public isBusy: boolean = false;
  public isLoading: boolean = true;
  public roles: Array<RoleModel> = [];

  constructor(@Inject(MAT_DIALOG_DATA) private custom: CustomModel,
    private dialogRef: MatDialogRef<CustomDialogComponent>,
    private customService: CustomService,
    private roleService: RoleService,
    private toastr: ToastrService,
    private authService: AuthService,
    private validationService: ValidationService) {
    this.model = custom;

    if (!this.isNew) {
      this.dialogTitle = this.model.title;
    }
  }

  public ngOnInit(): void {

    this.roleService.getRoles().subscribe({
      next: (response: Array<RoleModel>) => {
        this.roles = response;
        this.isLoading = false;
      }
    });
  }

  public get isNew(): boolean {
    return this.model == null ||
      this.model.customId == null ||
      this.model.customId == undefined ||
      this.model.customId == 0;
  }

  public canEdit(): boolean {
    return this.authService.canCurrentUserEdit;
  }

  public save(form: NgForm) {
    if (!this.canEdit()) {
      return;
    }

    if (this.validationService.isFormValid(form)) {
      this.isBusy = true;

      this.customService.saveCustom(this.model).subscribe({
        next: (response: CustomModel) => {
          this.model = response;
          this.toastr.success("Custom saved");
          this.dialogRef.close(this.model);
        },
        error: () => {
          this.isBusy = false;
        }
      });
    }
  }

  public getRoleTitle(roleId: number | null): string | null {
    return RoleTitleUtility.getRoleTitle(this.roles, roleId);
  }

  public close() {
    this.dialogRef.close();
  }
}

