<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<section class="dialog-container" *ngIf="!isLoading">
  <div>
    <h2 mat-dialog-title>{{ dialogTitle }}</h2>
  </div>

  <mat-dialog-content>
    <mat-form-field color="accent" *ngIf="canEdit()">
      <mat-label>Title</mat-label>
      <input matInput
              placeholder="Enter title..."
              name="title"
              [(ngModel)]="model.title"
              #title="ngModel"
              [disabled]="isBusy"
              required>
      <mat-error *ngIf="title.invalid">
        Title is required
      </mat-error>
    </mat-form-field>

    <div class="readonly-section" *ngIf="!canEdit()">
      <label class="readonly-label">
        Title
      </label>
      <div class="readonly-content">
        {{ model.title }}
      </div>
    </div>

    <mat-form-field color="accent" *ngIf="canEdit()">
      <mat-label>Description</mat-label>
      <textarea matInput
                placeholder="Enter description..."
                name="description"
                [disabled]="isBusy"
                [(ngModel)]="model.description"></textarea>
    </mat-form-field>

    <div class="readonly-section" *ngIf="!canEdit()">
      <label class="readonly-label">
        Description
      </label>
      <div class="readonly-content">
        {{ model.description }}
      </div>
    </div>

    <app-multi-autocomplete *ngIf="canEdit()"
                            [label]="'Categories'"
                            [placeholder]="'Select a Category'"
                            [options]="categories"
                            [selectedOptions]="model.categories"
                            [isDisabled]="isBusy">
    </app-multi-autocomplete>

    <div class="readonly-section" *ngIf="!canEdit()">
      <label class="readonly-label">
        Categories
      </label>
      <div class="readonly-content">
        <span *ngFor="let category of model.categories; let last = last">
          {{category.name}}<span *ngIf="!last">,</span>
        </span>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-raised-button class="button" (click)="close()" [disabled]="isBusy">Close</button>
    <button *ngIf="canEdit()" mat-raised-button color="accent" class="button accent-button" (click)="save()" [disabled]="isBusy">Save</button>
  </mat-dialog-actions>
</section>
