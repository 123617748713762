<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<section class="dialog-container" *ngIf="!isLoading">
  <div>
    <h2 mat-dialog-title>{{ dialogTitle }}</h2>
  </div>

  <mat-dialog-content>
    <form id="categoryForm" #categoryForm="ngForm" (ngSubmit)="save(categoryForm)">
      <mat-tab-group color="accent">
        <mat-tab label="Details" [disabled]="isBusy">
          <div class="mat-tab-content-container dialog-mat-tab-content-container">
            <mat-form-field color="accent" *ngIf="canEdit()">
              <mat-label>Name</mat-label>
              <input matInput
                     placeholder="Enter Name..."
                     name="name"
                     [(ngModel)]="model.name"
                     #name="ngModel"
                     [disabled]="isBusy"
                     required>
              <mat-error *ngIf="name.invalid">
                Name is Required
              </mat-error>
            </mat-form-field>

            <div class="readonly-section" *ngIf="!canEdit()">
              <label class="readonly-label">
                Name
              </label>
              <div class="readonly-content">
                {{model.name}}
              </div>
            </div>

            <mat-form-field color="accent" *ngIf="canEdit()">
              <mat-label>Description</mat-label>
              <textarea matInput
                        placeholder="Enter description..."
                        name="description"
                        [disabled]="isBusy"
                        [(ngModel)]="model.description"></textarea>
            </mat-form-field>

            <div class="readonly-section" *ngIf="!canEdit()">
              <label class="readonly-label">
                Description
              </label>
              <div class="readonly-content">
                {{model.description}}
              </div>
            </div>
          </div>
        </mat-tab>

        <mat-tab label="{{processesTabTitle}}" [disabled]="isNew || isBusy">
          <div class="mat-tab-container dialog-mat-tab-content-container">
            <table #categoryProcessesTable mat-table [dataSource]="processesTableData" matSort matSortActive="title" matSortDirection="asc" matSortDisableClear>
              <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
                <td mat-cell *matCellDef="let process">
                  <button mat-flat-button type="button" (click)="openProcess(process)" class="button">
                    {{process.referenceAndTitle}}
                  </button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="processesColumns"></tr>
              <tr mat-row *matRowDef="let process; columns: processesColumns"></tr>
            </table>
          </div>
        </mat-tab>
        <mat-tab label="{{documentTabTitle}}" [disabled]="isNew || isBusy">
          <div class="mat-tab-container dialog-mat-tab-content-container">
            <table #documentsTable mat-table [dataSource]="documentTableData" matSort matSortActive="documentName" matSortDirection="asc" matSortDisableClear>
              <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="documentName">Title</th>
                <td mat-cell *matCellDef="let document">
                  <a *ngIf="document.link && !document.fileDocument" [href]="document.link" target="_blank">{{ document.documentName }}</a>
                  <a *ngIf="!document.link && document.fileDocument" href="javascript:void(0)" (click)="downloadDocument(document)">{{ document.documentName }}</a>
                  <span *ngIf="(document.link && document.fileDocument) || (!document.link && !document.fileDocument)">{{ document.documentName }}</span>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="documentColumns"></tr>
              <tr mat-row *matRowDef="let document; columns: documentColumns"></tr>
            </table>
          </div>
        </mat-tab>
      </mat-tab-group>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()" [disabled]="isBusy" class="button">Close</button>
    <button type="submit" *ngIf="canEdit()" mat-raised-button color="accent" class="button accent-button" form="categoryForm" [disabled]="isBusy">Save</button>
  </mat-dialog-actions>
</section>
