import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Observable, map } from "rxjs";
import { StandardModel } from "../models/standard-model";
import { StandardSectionModel } from "../models/standard-section-model";
import { StandardRegisterModel } from "../models/standard-register-model";

@Injectable({
  providedIn: 'root'
})
export class StandardService {
  constructor(private apiService: ApiService) {
  }

  public getStandards(): Observable<Array<StandardModel>> {
    return this.apiService.get("standard/get-all")
      .pipe(map((response: Array<StandardModel>) => response.map(i => new StandardModel(i))));
  }

  public saveStandard(model: StandardModel): Observable<StandardModel> {
    return this.apiService.post("standard", model)
      .pipe(map(i => new StandardModel(i)));
  }

  public deleteStandard(id: number): Observable<void> {
    return this.apiService.post(`standard/delete/${id}`, {});
  }

  public getStandardSections(): Observable<Array<StandardSectionModel>> {
    return this.apiService.get(`standard/section/get-all`)
      .pipe(map((response: Array<StandardSectionModel>) => response.map(i => new StandardSectionModel(i))));
  }

  public saveStandardSection(model: StandardSectionModel): Observable<StandardSectionModel> {
    return this.apiService.post(`standard/section`, model)
      .pipe(map(i => new StandardSectionModel(i)));
  }

  public deleteStandardSection(id: number): Observable<void> {
    return this.apiService.post(`standard/section/delete/${id}`, {});
  }

  public getStandardSectionsForProcess(processId: number): Observable<Array<StandardSectionModel>> {
    return this.apiService.get(`standard/process/${processId}`)
      .pipe(map((response: Array<StandardSectionModel>) => response.map(i => new StandardSectionModel(i))));
  }

  public saveProcessStandardSection(processId: number, standardSectionId: number): Observable<StandardSectionModel> {
    return this.apiService.post(`standard/process/save/${processId}/${standardSectionId}`, {})
      .pipe(map(i => new StandardSectionModel(i)));
  }

  public deleteProcessStandardSection(processId: number, standardSectionId: number): Observable<void> {
    return this.apiService.post(`standard/process/delete/${processId}/${standardSectionId}`, {});
  }

  public getStandardRegister(): Observable<Array<StandardRegisterModel>> {
    return this.apiService.get(`standard/register`)
      .pipe(map((response: Array<StandardRegisterModel>) => response.map(i => new StandardRegisterModel(i))));
  }

  public exportRegister(): Observable<any> {
    return this.apiService.postExportRaw(`standard/register-export`, {});
  }
}
