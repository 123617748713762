import * as moment from "moment";
import { AutocompleteOptionModel } from "./autocomplete-option-model";

export class StandardSectionModel extends AutocompleteOptionModel {
  public standardSectionId: number;
  public standardId: number;
  public standardTitle: string | null;
  public title: string;
  public description: string | null;
  public ownerRoleId: number | null;
  public createdUtc: moment.Moment;
  public modifiedUtc: moment.Moment;

  constructor(data?: any) {
    super();

    if (data) {
      this.id = data.standardSectionId;
      this.displayValue = data.title;
      Object.assign(this, data);
    }
  }
}
