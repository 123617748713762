import { Observable, map } from "rxjs";
import { ProcessGroupModel } from "../models/process-group-model";
import { Injectable } from "@angular/core";
import { ProcessModel } from "../models/process-model";
import { ApiService } from "./api.service";
import { TreeNodeModel } from "../models/tree-node-model";
import { ProcessRevisionModel } from "../models/process-revision-model";
import { ProcessRegisterModel } from "../models/process-register-model";
import { ProcessRevisionRegisterModel } from "../models/process-revision-register-model";
import { ProcessExportModel } from "../models/process-export-model";

@Injectable({
  providedIn: 'root'
})
export class ProcessService {
  constructor(private apiService: ApiService) {
  }

  public getProcessGroups(): Observable<Array<ProcessGroupModel>> {
    return this.apiService.get("process/get-all")
      .pipe(map((response: Array<ProcessGroupModel>) => response.map(i => new ProcessGroupModel(i))));
  }

  public saveProcessGroup(model: ProcessGroupModel): Observable<ProcessGroupModel> {
    return this.apiService.post("process/group", model)
      .pipe(map(i => new ProcessGroupModel(i)));
  }

  public deleteProcessGroup(id: number): Observable<void> {
    return this.apiService.post(`process/group/delete/${id}`, {});
  }

  public getProcessesWithTasks(): Observable<Array<ProcessModel>> {
    return this.apiService.get(`process/get-all-with-tasks`)
      .pipe(map((response: Array<ProcessModel>) => response.map(i => new ProcessModel(i))));
  }

  public getProcess(id: number): Observable<ProcessModel> {
    return this.apiService.get(`process/${id}`)
      .pipe(map(i => new ProcessModel(i)));
  }

  public saveProcess(model: ProcessModel): Observable<ProcessModel> {
    return this.apiService.post("process", model)
      .pipe(map(i => new ProcessModel(i)));
  }

  public deleteProcess(id: number): Observable<void> {
    return this.apiService.post(`process/delete/${id}`, {});
  }

  public getRoleProcesses(roleId: number): Observable<Array<ProcessModel>> {
    return this.apiService.get(`process/get-role-processes/${roleId}`)
      .pipe(map((response: Array<ProcessModel>) => response.map(i => new ProcessModel(i))));
  }

  public getStandardSectionProcesses(standardSectionId: number): Observable<Array<ProcessModel>> {
    return this.apiService.get(`process/get-standard-section-processes/${standardSectionId}`)
      .pipe(map((response: Array<ProcessModel>) => response.map(i => new ProcessModel(i))));
  }

  public getDocumentProcesses(documentId: number): Observable<Array<ProcessModel>> {
    return this.apiService.get(`process/get-document-processes/${documentId}`)
      .pipe(map((response: Array<ProcessModel>) => response.map(i => new ProcessModel(i))));
  }

  public getCategoryProcesses(categoryId: number): Observable<Array<ProcessModel>> {
    return this.apiService.get(`process/get-category-processes/${categoryId}`)
      .pipe(map((response: Array<ProcessModel>) => response.map(i => new ProcessModel(i))));
  }

  public getProcessesTree(): Observable<Array<TreeNodeModel>> {
    return this.apiService.get("process/get-processes-tree")
      .pipe(map((response: Array<TreeNodeModel>) => response.map(i => new TreeNodeModel(i))));
  }

  public getProcessRevisionsForProcess(processId: number): Observable<Array<ProcessRevisionModel>> {
    return this.apiService.get(`process/get-revisions-for-process/${processId}`)
      .pipe(map((response: Array<ProcessRevisionModel>) => response.map(i => new ProcessRevisionModel(i))));
  }

  public saveProcessRevision(model: ProcessRevisionModel): Observable<ProcessRevisionModel> {
    return this.apiService.post("process/revision", model)
      .pipe(map(i => new ProcessRevisionModel(i)));
  }

  public deleteProcessRevision(processId: number): Observable<void> {
    return this.apiService.post(`process/delete-process-revision/${processId}`, {});
  }

  public getProcessRegister(): Observable<Array<ProcessRegisterModel>> {
    return this.apiService.get("process/register")
      .pipe(map((response: Array<ProcessRegisterModel>) => response.map(i => new ProcessRegisterModel(i))));
  }

  public getRevisionRegister(): Observable<Array<ProcessRevisionRegisterModel>> {
    return this.apiService.get("process/revision-register")
      .pipe(map((response: Array<ProcessRevisionRegisterModel>) => response.map(i => new ProcessRevisionRegisterModel(i))));
  }

  public exportRegister(): Observable<any> {
    return this.apiService.postExportRaw(`process/register-export`, {});
  }

  public exportRevisionRegister(): Observable<any> {
    return this.apiService.postExportRaw(`process/revision-register-export`, {});
  }

  public generateChecklistDocument(processId: number): Observable<any> {
    return this.apiService.postExportRaw(`process/checklist/${processId}`, {});
  }

  public exportProcess(model: ProcessExportModel): Observable<any> {
    return this.apiService.postExportRaw(`process/process-export`, model);
  }

  public copyProcess(processId: number, processGroupId: number) {
    return this.apiService.post(`process/copy/${processId}/${processGroupId}`, {});
  }
}
