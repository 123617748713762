import { Component, Inject } from '@angular/core'
import { ResourceGroupModel } from '../../../models/resource-group-model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ResourceService } from '../../../services/resource.service';
import { AuthService } from '../../../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { ValidationService } from '../../../services/validation-service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-resource-group-dialog',
  templateUrl: './resource-group-dialog.component.html',
  styleUrls: ['./resource-group-dialog.component.scss']
})

export class ResourceGroupDialogComponent {
  public model: ResourceGroupModel;
  public dialogTitle: string = 'New Resource Group';
  public isBusy: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) private resourceGroup: ResourceGroupModel,
    private dialogRef: MatDialogRef<ResourceGroupDialogComponent>,
    private resourceService: ResourceService,
    private toastr: ToastrService,
    private authService: AuthService,
    private validationService: ValidationService) {
    this.model = resourceGroup;

    if (this.model.resourceGroupId && this.model.resourceGroupId != 0) {
      this.dialogTitle = this.model.title;
    }
  }

  public canEdit(): boolean {
    return this.authService.canCurrentUserEdit;
  }

  public save(form: NgForm) {
    if (!this.canEdit()) {
      return;
    }

    if (this.validationService.isFormValid(form)) {
      this.isBusy = true;

      this.resourceService.saveResourceGroup(this.model).subscribe({
        next: (response: ResourceGroupModel) => {
          this.model = response
          this.toastr.success("Resource Group Saved");
          this.dialogRef.close(this.model);
        },
        error: () => {
          this.isBusy = false;
        }
      });
    }
  }

  public close() {
    this.dialogRef.close();
  }
}
