import * as moment from "moment";
export class ExceptionLogModel {
  public exceptionLogId: number;
  public endPoint: string | null;
  public path: string | null;
  public message: string | null;
  public stackTrace: string | null;
  public userId: number | null;
  public loggedUtc: moment.Moment | null;
  public hasBeenReviewed: boolean | null;
  constructor(data?: any) {
    if (data) {
      if (data.revisedDate) {
        data.revisedDate = moment(data.revisedDate);
      }

      Object.assign(this, data);
    }
  }
}
