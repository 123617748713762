import { Component, OnInit, ViewChild } from '@angular/core';
import { CustomModel } from '../../models/custom-model';
import { MatTableDataSource } from '@angular/material/table';
import { CustomGroupModel } from '../../models/custom-group-model';
import { MatSort } from '@angular/material/sort';
import { CustomService } from '../../services/custom.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AuthService } from '../../services/auth.service';
import { MatTableUtility } from '../../utility/mat-table-utility';
import { CustomDialogComponent } from '../custom-lists/custom-dialog/custom-dialog.component';
import { CustomGroupDialogComponent } from './custom-group-dialog/custom-group-dialog.component';

@Component({
  selector: 'app-custom-list',
  templateUrl: './custom-list.component.html',
  styleUrls: ['./custom-list.component.scss']
})

export class CustomListComponent implements OnInit {
  public customGroups: Array<CustomGroupModel>;
  public isLoading: boolean;
  public selectedCustomGroup: CustomGroupModel | null = null;

  public customTableData = new MatTableDataSource<CustomModel>([]);
  public customColumns: string[] = ['title', 'controls'];

  public customGroupTableData = new MatTableDataSource<CustomGroupModel>([]);
  public customGroupColumns: string[] = ['title', 'controls'];

  private customSort: MatSort;
  private customGroupSort: MatSort;

  @ViewChild('customGroupTable', { read: MatSort, static: false }) set customGroupSortValue(value: MatSort) {
    if (value) {
      this.customGroupSort = value;
      this.customGroupTableData.sort = this.customGroupSort;
    }
  };

  @ViewChild('customTable', { read: MatSort, static: false }) set customSortValue(value: MatSort) {
    if (value) {
      this.customSort = value;
      this.customTableData.sort = this.customSort;
    }
  };

  constructor(private customService: CustomService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    public authService: AuthService) {
    }

  public ngOnInit(): void {
    this.isLoading = true;

    this.customTableData.sortingDataAccessor = MatTableUtility.caseInsensitiveSortingDataAccessor;
    this.customGroupTableData.sortingDataAccessor = MatTableUtility.caseInsensitiveSortingDataAccessor;

    this.customService.getCustomGroups().subscribe({
      next: (response: Array<CustomGroupModel>) => {
        this.customGroups = response;
        this.refreshCustomGroupTableData();
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      }
    });
  }

  public canEdit(): boolean {
    return this.authService.canCurrentUserEdit;
  }

  public selectCustomGroup(customGroup: CustomGroupModel) {
    if (this.selectedCustomGroup != customGroup) {
      this.selectedCustomGroup = customGroup;
      this.refreshCustomTableData();
    }
  }

  public createCustomGroup() {
    if (!this.canEdit()) {
      return;
    }

    const customGroup = new CustomGroupModel();

    const dialogConfig: MatDialogConfig = {
      data: {
        ...customGroup,
      }
    };

    const dialogRef = this.dialog.open(CustomGroupDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: CustomGroupModel) => {
        if (result) {
          this.customGroups.push(result);
          this.selectedCustomGroup = result;
          this.refreshCustomTableData();
          this.refreshCustomGroupTableData();
        }
      }
    });
  }

  public editCustomGroup(customGroup: CustomGroupModel) {
    const dialogConfig: MatDialogConfig = {
      data: {
        ...customGroup,
      },
      autoFocus: this.canEdit(),
    };

    const dialogRef = this.dialog.open(CustomGroupDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: CustomGroupModel) => {
        if (result) {
          const index = this.customGroups.findIndex(i => i.customGroupId == result.customGroupId);
          this.customGroups[index] = result;
          this.refreshCustomGroupTableData();
        }
      }
    });
  }

  public deleteCustomGroup(customGroup: CustomGroupModel) {
    if (!this.canEdit()) {
      return;
    }

    if (!confirm(`Are you sure you want to delete Custom Group "${customGroup.title}"?`)) {
      return;
    }

    this.customService.deleteCustomGroup(customGroup.customGroupId).subscribe({
      next: () => {
        this.customGroups.splice(this.customGroups.indexOf(customGroup), 1);
        this.refreshCustomGroupTableData();
        this.toastr.success(`Custom "${customGroup.title}" deleted`);
      }
    });
  }

  public createCustom() {
    if (!this.canEdit()) {
      return;
    }

    if (!this.selectedCustomGroup) {
      return;
    }

    const custom = new CustomModel();
    custom.customGroupId = this.selectedCustomGroup.customGroupId;

    const dialogConfig: MatDialogConfig = {
      data: {
        ...custom
      }
    };

    const dialogRef = this.dialog.open(CustomDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: CustomModel) => {
        if (result) {
          this.selectedCustomGroup!.customs.push(result);
          this.refreshCustomTableData();
        }
      }
    });
  }

  public editCustom(custom: CustomModel) {
    const dialogConfig: MatDialogConfig = {
      data: {
        ...custom,
      }
    };

    const dialogRef = this.dialog.open(CustomDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: CustomModel) => {
        if (result) {
          const customGroup = this.customGroups.find(i => i.customGroupId == result.customGroupId);
          if (customGroup) {
            const index = customGroup.customs.findIndex(i => i.customId == result.customId);
            customGroup.customs[index] = result;
            this.refreshCustomTableData();
          }
        }
      }
    });
  }

  public deleteCustom(custom: CustomModel) {
    if (!this.canEdit()) {
      return
    }

    if (!confirm(`Are you sure you want to delete Group "${custom.title}"?`)) {
      return;
    }

    this.customService.deleteCustom(custom.customId).subscribe({
      next: () => {
        const customGroup = this.customGroups.find(i => i.customGroupId == custom.customGroupId);
        if (customGroup) {
          customGroup.customs.splice(customGroup.customs.indexOf(custom), 1);
          this.refreshCustomTableData();
          this.toastr.success(`Group "${custom.title}" deleted`);
        }
      }
    });
  }

  private refreshCustomGroupTableData(): void {
    this.customGroupTableData.data = this.customGroups;
  }

  private refreshCustomTableData(): void {
    if (this.selectedCustomGroup) {
      this.customTableData.data = this.selectedCustomGroup.customs;
    } else {
      this.customTableData.data = [];
    }
  }
}
