import * as moment from "moment";
export class AuditLogModel {
  public auditLogId: number;
  public userId: number | null;
  public type: string;
  public tableName: string;
  public changeUtc: moment.Moment;
  public oldValues: string | null;
  public newValues: string | null;
  public affectedColumns: string | null;
  public primaryKey: number;

  public userDisplayName: string

  constructor(data?: any) {
    if (data) {
      if (data.changeUtc) {
        data.changeUtc = moment(data.changeUtc);
      }

      Object.assign(this, data)
    }
  }
}
