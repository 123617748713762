import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { JwtModule } from '@auth0/angular-jwt';
import { AppRoutingModule } from './app-routing-module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

import { AppComponent } from './app.component';
import { SignInComponent } from './views/sign-in/sign-in.component';
import { DocumentListComponent } from './views/document/document-list.component';
import { RoleListComponent } from './views/roles/role-list.component';
import { ProcessListComponent } from './views/processes/process-list.component';
import { ProcessComponent } from './views/processes/process/process.component';
import { AdminComponent } from './views/admin/admin.component';
import { CompaniesComponent } from './views/admin/companies/companies.component';
import { CompanyComponent } from './views/admin/company/company.component';
import { UserComponent } from './views/admin/user/user.component';
import { LoadingSpinnerComponent } from './shared/loading-spinner/loading-spinner.component';
import { MaterialModule } from './material.module';
import { DocumentDialogComponent } from './views/document/document-dialog/document-dialog.component';
import { CompanySelectComponent } from './views/company-select/company-select.component';
import { DocumentGroupDialogComponent } from './views/document/document-group-dialog/document-group-dialog.component';
import { RoleDialogComponent } from './views/roles/role-dialog/role-dialog.component';
import { ProcessTaskDialogComponent } from './views/processes/process/process-task-dialog/process-task-dialog.component';
import { RoleGroupDialogComponent } from './views/roles/role-group-dialog/role-group-dialog.component';
import { ProcessTaskBranchDialogComponent } from './views/processes/process/process-task-branch-dialog/process-task-branch-dialog.component';
import { ProcessGroupDialogComponent } from './views/processes/process-group-dialog/process-group-dialog.component';
import { ChangePasswordComponent } from './views/change-password/change-password.component';
import { VerifyComponent } from './views/verify/verify.component';
import { ProcessTaskDocumentDialogComponent } from './views/processes/process/process-task-document-dialog/process-task-document-dialog.component';
import { InfoDialogComponent } from './shared/dialogs/info-dialog/info-dialog.component';
import { RequestInterceptor } from './utility/request-interceptor';
import { AutocompleteComponent } from './shared/autocomplete/autocomplete.component';
import { MultiAutocompleteComponent } from './shared/multi-autocomplete/multi-autocomplete.component';
import { HomeComponent } from './views/home/home.component';
import { StorageKeyConstant } from './constants/storage-key-constant';
import { ProcessRevisionListDialogComponent } from './views/processes/process/process-revision-list-dialog/process-revision-list-dialog.component';
import { ProcessRevisionDialogComponent } from './views/processes/process/process-revision-dialog/process-revision-dialog.component';
import { MultiFactorAuthenticationDialogComponent } from './views/sign-in/multi-factor-authentication-dialog/multi-factor-authentication-dialog.component';
import { StandardListComponent } from './views/standard/standard-list.component';
import { StandardDialogComponent } from './views/standard/standard-dialog/standard-dialog.component';
import { StandardSectionDialogComponent } from './views/standard/standard-section-dialog/standard-section-dialog.component';
import { ProcessStandardListDialogComponent } from './views/processes/process/process-standard-list-dialog/process-standard-list-dialog.component';
import { StandardSectionSelectDialogComponent } from './shared/standard-section-select-dialog/standard-section-select-dialog.component';
import { ProcessTaskProcessSelectDialogComponent } from './views/processes/process/process-task-process-select-dialog/process-task-process-select-dialog.component';
import { DocumentSelectDialogComponent } from './shared/document-select-dialog/document-select-dialog.component';
import { CustomListComponent } from './views/custom-lists/custom-list.component';
import { CustomDialogComponent } from './views/custom-lists/custom-dialog/custom-dialog.component';
import { CustomGroupDialogComponent } from './views/custom-lists/custom-group-dialog/custom-group-dialog.component';
import { CategoryListComponent } from './views/categories/category-list.component';
import { CategoryDialogComponent } from './views/categories/category-dialog/category-dialog.component';
import { ProcessCustomListDialogComponent } from './views/processes/process/process-custom-list-dialog/process-custom-list-dialog.component';
import { CustomSelectDialogComponent } from './shared/custom-select-dialog/custom-select-dialog.component';
import { DocumentRegisterComponent } from './views/document-register/document-register.component';
import { ProcessRegisterComponent } from './views/process-register/process-register.component';
import { RoleRegisterComponent } from './views/role-register/role-register.component';
import { ProcessRevisionRegisterComponent } from './views/process-revision-register/process-revision-register.component';
import { CustomRegisterComponent } from './views/custom-register/custom-register.component';
import { AuthInterceptor } from './utility/auth-interceptor';
import { ConfigurationComponent } from './views/admin/configuration/configuration.component';
import { ResourceListComponent } from './views/resources/resource-list.component';
import { ResourceGroupDialogComponent } from './views/resources/resource-group-dialog/resource-group-dialog.component';
import { ResourceDialogComponent } from './views/resources/resource-dialog/resource-dialog.component';
import { ProcessTaskCustomDialogComponent } from './views/processes/process/process-task-custom-dialog/process-task-custom-dialog.component';
import { ProcessResourceListDialogComponent } from './views/processes/process/process-resources-list-dialog/process-resource-list-dialog.component';
import { ResourceSelectDialogComponent } from './shared/resource-select-dialog/resource-select-dialog.component';
import { ProcessTaskResourceDialogComponent } from './views/processes/process/process-task-resource-dialog/process-task-resource-dialog.component';
import { ResourceRegisterComponent } from './views/resource-register/resource-register.component';
import { StandardRegisterComponent } from './views/standard-register/standard-register.component';
import { ProcessCopyDialogComponent } from './views/processes/process/process-copy-dialog/process-copy-dialog.component';
import { UserRoleDialogComponent } from './views/roles/user-roles-dialog/user-role-dialog.component';
import { AuditLogComponent } from './views/audit-log/audit-log.component';
import { ExceptionLogComponent } from './views/exception-log/exception-log.component';

export function tokenGetter() {
  return localStorage.getItem(StorageKeyConstant.TOKEN_KEY);
}

@NgModule({ declarations: [
        AdminComponent,
        AppComponent,
        ChangePasswordComponent,
        CompaniesComponent,
        CompanySelectComponent,
        CompanyComponent,
        DocumentDialogComponent,
        DocumentGroupDialogComponent,
        DocumentListComponent,
        HomeComponent,
        InfoDialogComponent,
        LoadingSpinnerComponent,
        ProcessListComponent,
        ProcessComponent,
        ProcessGroupDialogComponent,
        ProcessTaskBranchDialogComponent,
        ProcessTaskDialogComponent,
        ProcessTaskDocumentDialogComponent,
        RoleDialogComponent,
        RoleGroupDialogComponent,
        RoleListComponent,
        SignInComponent,
        UserComponent,
        VerifyComponent,
        AutocompleteComponent,
        MultiAutocompleteComponent,
        ProcessRevisionListDialogComponent,
        ProcessRevisionDialogComponent,
        MultiFactorAuthenticationDialogComponent,
        StandardListComponent,
        StandardDialogComponent,
        StandardSectionDialogComponent,
        CustomListComponent,
        CustomDialogComponent,
        CustomGroupDialogComponent,
        ResourceListComponent,
        ResourceGroupDialogComponent,
        ResourceDialogComponent,
        ProcessResourceListDialogComponent,
        ResourceSelectDialogComponent,
        CategoryListComponent,
        CategoryDialogComponent,
        ProcessStandardListDialogComponent,
        StandardSectionSelectDialogComponent,
        ProcessTaskProcessSelectDialogComponent,
        DocumentSelectDialogComponent,
        ProcessCustomListDialogComponent,
        CustomSelectDialogComponent,
        DocumentRegisterComponent,
        ProcessRegisterComponent,
        ProcessRevisionRegisterComponent,
        RoleRegisterComponent,
        CustomRegisterComponent,
        ConfigurationComponent,
        ProcessTaskCustomDialogComponent,
        ProcessTaskResourceDialogComponent,
        ProcessTaskCustomDialogComponent,
        ResourceRegisterComponent,
        StandardRegisterComponent,
        ProcessCopyDialogComponent,
        UserRoleDialogComponent,
        AuditLogComponent,
        ExceptionLogComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        MaterialModule,
        AppRoutingModule,
        FormsModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter,
                allowedDomains: ["*"],
                disallowedRoutes: []
            }
        }),
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            positionClass: 'toast-bottom-right',
            closeButton: true,
            enableHtml: true,
        })], providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestInterceptor,
            multi: true
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
