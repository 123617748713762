import * as moment from "moment";
import { AutocompleteOptionModel } from "./autocomplete-option-model";

export class CustomModel extends AutocompleteOptionModel {
  public customGroupId: number;
  public customId: number;
  public title: string;
  public unitOfMeasure: string | null;
  public value: string | null;
  public description: string | null;
  public ownerRoleId: number | null;
  public createdUtc: moment.Moment;
  public modifiedUtc: moment.Moment;
  public customGroupTitle: string;

  constructor(data?: any) {
    super();

    if (data) {
      this.id = data.customId;
      this.displayValue = data.title;
      Object.assign(this, data);
    }
  }
}
