<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<div *ngIf="!isLoading">
  <div class="col-sm-12">
    <h2 class="col-sm-3">Configuration</h2>

    <mat-form-field color="accent">
      <mat-label>Attachable File Types</mat-label>
      <input matInput
             name="attachableFileTypes"
             [(ngModel)]="model.attachableFileTypes">
    </mat-form-field>

    <mat-form-field color="accent">
      <mat-label>Attachable File Max Size In MB</mat-label>
      <input matInput
             type="number"
             name="attachableFileMaxSizeMB"
             [(ngModel)]="model.attachableFileMaxSizeMB">
    </mat-form-field>

    <div>
      <input type="file" #file (change)="uploadFile(file.files)" class="file-input">
      <div>
        <span *ngIf="model.userGuideFileDocument && model.userGuideFileDocument.fileName"><a href="javascript:void(0);" (click)="downloadFile()">{{ model.userGuideFileDocument.fileName }}</a> </span>
        <span *ngIf="!userGuideFormData && !model.userGuideFileDocument">No file uploaded.</span>
        <span *ngIf="userGuideFormData && !model.userGuideFileDocument">{{ userGuideFileName }}</span>
        <button mat-mini-fab color="accent" (click)="file.click()" class="accent-button document-button">
          <mat-icon>attach_file</mat-icon>
        </button>
        <button *ngIf="model.userGuideFileDocument || userGuideFormData" mat-mini-fab color="accent" (click)="deleteFile()" class="accent-button document-button">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>

    <button mat-flat-button color="accent" class="button accent-button" (click)="save()">Save</button>
  </div>
</div>

