import { Component } from '@angular/core';
import { ConfigurationService } from '../../../services/configuration.service';
import { ConfigurationModel } from '../../../models/configuration-model';
import { ToastrService } from 'ngx-toastr';
import { FileDocumentMetadataModel } from '../../../models/file-document-metadata-model';
import { FileDocumentUtility } from '../../../utility/file-document-utility';
import { FileDocumentService } from '../../../services/file-document-service';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})
export class ConfigurationComponent {
  public isLoading: boolean = true;
  public model: ConfigurationModel;
  public userGuideFileName: string | null;
  public userGuideFormData: FormData | null;

  constructor(public configurationService: ConfigurationService,
    private fileDocumentService: FileDocumentService,
    private toastr: ToastrService) {
  }

  public ngOnInit(): void {
    this.configurationService.getConfiguration().subscribe({
      next: (response: ConfigurationModel) => {
        this.model = response;
        this.isLoading = false;
      }
    });
  }

  public save() {
    this.configurationService.saveConfiguration(this.model).subscribe({
      next: (response: ConfigurationModel) => {
        this.model = response;
        if (this.userGuideFormData) {
          this.configurationService.uploadUserGuide(this.userGuideFormData).subscribe({
            next: (response: FileDocumentMetadataModel | null) => {
              this.model.userGuideFileDocument = response;
              this.toastr.success("Saved");
            }
          });
        } else {
          this.toastr.success("Saved");
        }
      }
    });
  }

  public uploadFile(files: FileList | null) {
    if (!files || files.length != 1) {
      return;
    }

    let fileToUpload = files[0] as File;

    if (fileToUpload.name.includes(";")) {
      this.toastr.error("File name cannot contain the ; character");
      return;
    }

    let formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);

    this.userGuideFileName = fileToUpload.name;
    this.userGuideFormData = formData;
  }

  public downloadFile() {
    if (this.model.userGuideFileDocument) {
      this.fileDocumentService.getFileDocument(this.model.userGuideFileDocument).subscribe({
        next: (response: any) => {
          FileDocumentUtility.openFileDocument(response);
        }
      });
    }
  }

  public deleteFile() {
    this.userGuideFileName = null;
    this.userGuideFormData = null;
    this.model.userGuideFileDocumentId = null;
    this.model.userGuideFileDocument = null;
  }
}
