export class ProcessTaskCustomModel {
  public processTaskCustomId: number;
  public processTaskId: number;
  public customId: number;
  public value: string | null;

  public customDisplayName: string;

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
