import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StandardModel } from '../../../models/standard-model';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../services/auth.service';
import { StandardService } from '../../../services/standard.service';
import { ValidationService } from '../../../services/validation-service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-standard-dialog',
  templateUrl: './standard-dialog.component.html',
  styleUrls: ['./standard-dialog.component.scss']
})
export class StandardDialogComponent {
  public dialogTitle: string = "New Standard";
  public isBusy: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public model: StandardModel,
    private dialogRef: MatDialogRef<StandardDialogComponent>,
    private standardService: StandardService,
    private toastr: ToastrService,
    private authService: AuthService,
    private validationService: ValidationService) {

    if (this.model.standardId && this.model.standardId != 0) {
      this.dialogTitle = this.model.title;
    }
  }

  public canEdit(): boolean {
    return this.authService.canCurrentUserEdit;
  }

  public save(form: NgForm) {
    if (!this.canEdit()) {
      return;
    }

    if (this.validationService.isFormValid(form)) {
      this.isBusy = true;

      this.standardService.saveStandard(this.model).subscribe({
        next: (response: StandardModel) => {
          this.model = response;
          this.toastr.success("Standard saved");
          this.dialogRef.close(this.model);
        },
        error: () => {
          this.isBusy = false;
        }
      });
    }
  }

  public close() {
    this.dialogRef.close();
  }
}
