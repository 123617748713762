import * as moment from "moment";
import { AutocompleteOptionModel } from "./autocomplete-option-model";
import { CategoryModel } from "./category-model";
import { FileDocumentMetadataModel } from "./file-document-metadata-model";

export class DocumentModel extends AutocompleteOptionModel {
  public documentId: number;
  public documentGroupId: number;
  public identifier: string;
  public title: string | null;
  public description: string | null;
  public link: string | undefined;
  public fileDocumentId: number | null;
  public version: string | null;
  public retention: string | null;
  public reviewDueDate: Date | null;
  public reviewFrequency: string | null;
  public ownerRoleId: number | null;
  public approverRoleId: number | null;
  public createdUtc: moment.Moment;
  public modifiedUtc: moment.Moment;

  public fileDocument: FileDocumentMetadataModel | null;
  public documentName: string;
  public categories: Array<CategoryModel> = [];

  constructor(data?: any) {
    super();

    if (data) {
      this.id = data.documentId;
      this.displayValue = data.documentName;

      if (data.categories) {
        data.categories = data.categories.map((i: any) => new CategoryModel(i));
      }

      Object.assign(this, data);
    }
  }
}
