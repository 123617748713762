import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomService } from '../../../services/custom.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../services/auth.service';
import { CustomGroupModel } from '../../../models/custom-group-model';
import { ValidationService } from '../../../services/validation-service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-custom-group-dialog',
  templateUrl: './custom-group-dialog.component.html',
  styleUrls: ['./custom-group-dialog.component.scss']
})

export class CustomGroupDialogComponent {
  public model: CustomGroupModel;
  public dialogTitle: string = "New Custom Group";
  public isBusy: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) private customGroup: CustomGroupModel,
    private dialogRef: MatDialogRef<CustomGroupDialogComponent>,
    private customService: CustomService,
    private toastr: ToastrService,
    private authService: AuthService,
    private validationService: ValidationService) {
    this.model = customGroup;

    if (this.model.customGroupId && this.model.customGroupId != 0) {
      this.dialogTitle = this.model.title;
    }
  }

  public canEdit(): boolean {
    return this.authService.canCurrentUserEdit;
  }

  public save(form: NgForm) {
    if (!this.canEdit()) {
      return;
    }

    if (this.validationService.isFormValid(form)) {
      this.isBusy = true;

      this.customService.saveCustomGroup(this.model).subscribe({
        next: (response: CustomGroupModel) => {
          this.model = response
          this.toastr.success("Custom Group Saved");
          this.dialogRef.close(this.model);
        },
        error: () => {
          this.isBusy = false;
        }
      });
    }
  }

  public close() {
    this.dialogRef.close();
  }
}
