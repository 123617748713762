import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from '../services/auth.service';

export const unauthenticatedGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const jwtHelper = inject(JwtHelperService);
  const router = inject(Router);
  const authService = inject(AuthService);

  const token = authService.token;

  if (!authService.currentUser || !token || jwtHelper.isTokenExpired(token)) {
    return true;
  }

  router.navigate([`${authService.currentUser!.companyName}`]);

  return false;
};
